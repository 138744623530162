@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-toastify/dist/ReactToastify.css';



/*@font-face {
  font-family: 'Petit_Cochon';
  src: url('C:/Users/maugu/Desktop/paws/paws2home-ui/paws2home-ui/src/resources/fonts/Petit_Cochon.ttf') format("truetype"); /* poner la dirección completa 
  font-weight: bold;
  font-style: bold;
}


.petitC{
  font-family: 'Petit_Cochon';
  font-weight: 'bold';
}*/

*{
  font-family: 'Comfortaa', 'sans-serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

